

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL} from "@/config";
import Axios from "axios";

import { LoanAndProperty } from "@/models/pos/loan-and-property.model";
import { DemographicInformation } from "@/models/pos/demographic-info.model";
import { Acknowledgement } from "@/models/pos/acknowledgement.model";
import { FinancialInfo } from "@/models/pos/financial-info.model";
import { Declaration } from "@/models/pos/declaration.model";
import { TransactionDetail } from "@/models/pos/transaction-detail.model";
import { BorrowerInformation } from "@/models/pos/borrower-info.model";
import { borrowerData } from "@/models/borrowerData.model";
@Component
export default class AddBorrowerComponent extends Vue {
  public inProcess = false;
  public borrowerData = new borrowerData();
  public loanAndProperty = new LoanAndProperty();
  public borrowerInfoData: any = new BorrowerInformation();
  public demographicInfo = new DemographicInformation();
  public acknowledgement = new Acknowledgement();
  public financialInfo = new FinancialInfo();
  public declaration = new Declaration();
  public transactionDetail = new TransactionDetail();
  public loanTxnId = null;
  public source = null;
  public headerText = null;
  get userType() {
    return this.$store.state.sessionObject.type;
  }
  public async submitBorrowerInfo() {
    try {
      let formValid = await this.$validator.validateAll("borrowerInfo");
      let haveDuplicate = this.borrowerInfoData.borrowerInfo.find(a => a.duplicateEmail);
      if (!formValid || haveDuplicate) {
        return;
      }
      this.inProcess = true;
      let body: any = {
        borrowerInfo: this.borrowerInfoData,
        loanAndProperty: this.loanAndProperty,
        demographicInfo: this.demographicInfo,
        acknowledgement: this.acknowledgement,
        financialInfo: this.financialInfo,
        declaration: this.declaration,
        transactionDetail: this.transactionDetail,
        borrowerData: this.borrowerData
      };
      body.borrowerData.source = this.$route.query.source; //Saving Source in borrower collection.
      body.borrowerData.loanInformation.enumerationType = this.$route.query.enumerationType || 'mismo';
      if (!body.borrowerData.loanTxnId) {
        body.borrowerData.loanTxnId = this.$random(8);
      }
      this.loanTxnId = body.borrowerData.loanTxnId;
      body.borrowerData.addedBy = this.$userId;
      body.borrowerData.originatorModelName =
        this.userType == "Admin Staff" ? "Admin-Staff" : this.userType;
      body.borrowerData.brokerId = this.$brokerId;
      body.loanAndProperty.loanTxnId = body.borrowerInfo.loanTxnId = body.demographicInfo.loanTxnId = body.acknowledgement.loanTxnId = body.financialInfo.loanTxnId = body.declaration.loanTxnId = body.transactionDetail.loanTxnId =
        body.borrowerData.loanTxnId;
      //Sending data to the Server In Borrowers Collection
      let response = await Axios.post(
        BASE_API_URL + "loan/submitBorrowerInfo",
        body);
      if (response.data.type == "error") {
        this.borrowerInfoData.borrowerInfo = response.data.borrowerInfo;
      } else if (response.data.type == "success") {
        this.$snotify.success("Data submitted");
        if (this.source == "runPricing") {
          this.$router.push({
            path: this.$getCurrentUserTypePath("pricing"),
            query: { id: this.loanTxnId }
          });
        }
        if (this.source == "import-3.2") {
          this.$router.push({
            path: this.$getCurrentUserTypePath("import-3.2"),
            query: { id: this.loanTxnId }
          });
        }
        if (this.source == "LOS") {
          this.$router.push({
            path: this.$getCurrentUserTypePath("current-urla"),
            query: { id: this.loanTxnId }
          });
        }
        if (this.source == "digitalMortgage") {
          this.$router.push({
            path: this.$getCurrentUserTypePath("digital-mortgage"),
            query: { id: this.loanTxnId }
          });
        }
      }
      this.inProcess = false;
    } catch (error) {
      this.inProcess = false;
      console.log(error);
    }
  }

  public checkDuplicate() {
    try {
      this.borrowerInfoData.borrowerInfo.forEach(el => { el.duplicateEmail = false; });
      this.borrowerInfoData.borrowerInfo.forEach((element, index) => {
        this.borrowerInfoData.borrowerInfo.forEach((el, i) => {
          if (index < i) {
            if (
              element.personalInfo.contactInformation.email &&
              element.personalInfo.contactInformation.email ==
                el.personalInfo.contactInformation.email
            )
              el.duplicateEmail = true;
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
  public manageHeaderText() {
    if (this.source == "runPricing") {
      this.headerText = "Price My Loan";
    } else if (this.source == "skipPricing") {
      this.headerText = "Import 3.2";
    } else if (this.source == "LOS") {
      this.headerText = "1003";
    } else if (this.source == "digitalMortgage") {
      this.headerText = "Digital Mortgage Application";
    }
  }
  public addBorrower() {
    let borrower: any = new BorrowerInformation();
    this.borrowerInfoData.borrowerInfo.push({
      ...borrower.borrowerInfo[0],
      duplicateEmail: false
    });
  }

  public removeBorrower(index) {
    let borrowerIndex = this.borrowerInfoData.borrowerInfo[index].personalInfo.jointWithCoBorrowerIndex;
    if(borrowerIndex != null){
      this.borrowerInfoData.borrowerInfo.forEach((el,i)=>{
        if(el.personalInfo.jointWithCoBorrowerIndex == index){
          el.personalInfo.jointWithCoBorrowerIndex = null;
          el.personalInfo.relationWithBorrower = null;
        }
      });
    }
    this.borrowerInfoData.borrowerInfo.splice(index, 1);
  }

  getBorrowerName(index) {
    let borrower = this.borrowerInfoData.borrowerInfo[index].personalInfo;
    let name = null;
    name =
      borrower.name.firstName +
      " " +
      (borrower.name.lastName ? borrower.name.lastName : "");
    return name;
  }
  /**
   *  Check Borrower have Co-Borrower or Not
   */
  haveAdditionalBorrower(index) {
    let borrower = this.borrowerInfoData.borrowerInfo[index].personalInfo;
    if (
      borrower.name.firstName &&
      borrower.relationWithBorrower != "Spouse" &&
      !borrower.jointWithCoBorrowerIndex
    ) {
      return true;
    } else {
      return false;
    }
  }
  /**
   *  remove jointWIthCoBorrowerIndex if relation with borrower is not spouse
   */
  changeBorrowerRelation(index) {
    if (
      this.borrowerInfoData.borrowerInfo[index].personalInfo
        .relationWithBorrower != "Spouse"
    ) {
      // let borrowerIndex = this.borrowerInfoData.borrowerInfo[index].personalInfo
      //   .jointWithCoBorrowerIndex;
      // if (borrowerIndex != null) {
      //   this.borrowerInfoData.borrowerInfo[
      //     borrowerIndex
      //   ].personalInfo.jointWithCoBorrowerIndex = null;
      // }
      this.borrowerInfoData.borrowerInfo[index].personalInfo.maritalStatus.status = null;
      this.borrowerInfoData.borrowerInfo[
        index
      ].personalInfo.jointWithCoBorrowerIndex = null;
    }
    this.borrowerInfoData = JSON.parse(JSON.stringify(this.borrowerInfoData));
  }
  /**
   *  add coBorrower index in borrower information and remove from otherBorrower's information if have that same index
   */
  changeBorrowerRelationIndex(index) {    
    this.borrowerInfoData.borrowerInfo.forEach(el=>{
      if(index == el.personalInfo.jointWithCoBorrowerIndex && el.personalInfo.relationWithBorrower != 'Spouse'){
        el.personalInfo.jointWithCoBorrowerIndex = null;
      }
    });
    let borrowerIndex = this.borrowerInfoData.borrowerInfo[index].personalInfo
      .jointWithCoBorrowerIndex;
    this.borrowerInfoData.borrowerInfo.forEach((element, i) => {
      if (
        i != borrowerIndex &&
        element.personalInfo.relationWithBorrower != "Spouse" &&
        element.personalInfo.jointWithCoBorrowerIndex == borrowerIndex
      ) {
        element.personalInfo.jointWithCoBorrowerIndex = null;
      } else if (
        i == borrowerIndex &&
        element.personalInfo.relationWithBorrower != "Spouse"
      ) {
        element.personalInfo.jointWithCoBorrowerIndex = index;
      }
    });
    this.borrowerInfoData = JSON.parse(JSON.stringify(this.borrowerInfoData));
  }

  async mounted() {
    this.source = this.$route.query.source;
    this.borrowerInfoData.borrowerInfo = this.borrowerInfoData.borrowerInfo.map(
      a => {
        return { ...a, duplicateEmail: false };
      }
    );
    this.manageHeaderText();
  }
}
