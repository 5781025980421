

import { Component, Vue, Prop } from "vue-property-decorator";
import { HollowDotsSpinner } from "epic-spinners";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import { borrowerData } from "@/models/borrowerData.model";
import { BorrowerInformation } from "@/models/pos/borrower-info.model";
import { LoanAndProperty } from "@/models/pos/loan-and-property.model";
import { Declaration } from "@/models/pos/declaration.model";
import { DemographicInformation } from "@/models/pos/demographic-info.model";
import { FinancialInfo } from "@/models/pos/financial-info.model";
import { Acknowledgement } from "@/models/pos/acknowledgement.model";
import { LoanOriginatorInfo } from "@/models/pos/loan-originator-info.model";
import { TransactionDetail } from "@/models/pos/transaction-detail.model";
import stores from "@/store/store";
const store: any = stores;
@Component({
  components: { HollowDotsSpinner }
})
export default class AddFnmBorrowerInfoComponent extends Vue {
  @Prop()
  public borrowers;
  @Prop()
  public ssn;
  @Prop()
  public dataType: any;
  @Prop()
  public mappingInfo: any;
  public duplicate: Boolean = false;
  public borrowerData = new borrowerData();
  public borrowerInformation = new BorrowerInformation();
  public loanAndProperty = new LoanAndProperty();
  public declaration = new Declaration();
  public demographicInformation = new DemographicInformation();
  public financialInfo = new FinancialInfo();
  public acknowledgement = new Acknowledgement();
  public loanOriginatorInfo = new LoanOriginatorInfo();
  public transactionDetail = new TransactionDetail();
  public loanTxnId: any = null;
  public selectedDatatype: any = null;
  public borrowerInfo = [];
  public dontAsk = false;
  public disableButton = false;

  public async dupliacteMailAndPhone() {
    try {
      this.duplicate = false;
      this.borrowerInfo.forEach(element => { element.duplicateEmail = false; });
      this.borrowerInfo.forEach((data, i) => {
        this.borrowerInfo.forEach((e, j) => {
          if (i != j) {
            if (
              data.personalInfo.contactInformation.email ==
              e.personalInfo.contactInformation.email
            ) {
              this.duplicate = true;
              e.duplicateEmail = true;
              this.$store.state.wemloLoader = false;
            }
          }
        });
      });
      this.borrowerInfo = JSON.parse(JSON.stringify(this.borrowerInfo));
      if (this.duplicate) {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async saveFnmFile() {
    this.$store.state.wemloLoader = true;
    this.disableButton = true;
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.$store.state.wemloLoader = false;
        this.disableButton = false;
        return;
      }
      await this.dupliacteMailAndPhone();
      if (!this.duplicate) {
        let mapping = await this.getRelationshipMapping();
        let obj = {
          borrowerData: this.borrowerData,
          borrowerInfo: this.borrowerInformation,
          loanAndProperty: this.loanAndProperty,
          declaration: this.declaration,
          demographicInformation: this.demographicInformation,
          financialInfo: this.financialInfo,
          acknowledgement: this.acknowledgement,
          transactionDetail: this.transactionDetail,
          relationshipMapping:
            this.selectedDatatype == "currentData" ? mapping : null
        };
        let body = {
          data: this.borrowerInfo,
          info: obj,
          loanTxnId: this.loanTxnId,
          ediFile: this.$route.query.ediFile,
          brokerId: this.$brokerId,
          addedBy: this.$userId,
          userType: this.$userType,
          ssnToDelete: this.selectedDatatype == "currentData" ? this.ssn : null,
          dataType: this.selectedDatatype,
          source: this.$route.query.source
        };
        let response = await Axios.post(
          BASE_API_URL + "los/readFnmFile",
          body);
        if (response.data.status == 500) {
          this.borrowerInfo = response.data.data;
          this.borrowerInfo = JSON.parse(JSON.stringify(this.borrowerInfo));
          this.$store.state.wemloLoader = false;
          this.disableButton = false;
        } else {
          this.$snotify.success("Saved successfully");
          if (this.dontAsk) {
            this.$router.push({
              path: this.$getCurrentUserTypePath("broker-loan-documents"),
              query: { id: this.loanTxnId, edit: 'true' }
            });
          } else {
            this.$router.push({
              path: this.$getCurrentUserTypePath("blank-backGround-popUp"),
              query: {
                id: this.loanTxnId,
                modalName: "documentRequestModal",
                source: "Add Fnm Borrower"
              }
            });
          }
        }
      }
    } catch (error) {
      console.log(error);

      this.$router.push({
          path: this.$getCurrentUserTypePath("import-3.2"),
          query: { id: this.$route.query.id, source: "import-3.2" }
        });
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public async getRelationshipMapping() {
    try {
      let relationshipMapping = [];
      this.borrowerInfo.forEach((e, i) => {
        if (e.userType == "Borrower") {
          relationshipMapping.push({
            borrowerIndex: i,
            coBorrowerIndex: []
          });
        }
      });
      relationshipMapping.forEach(el => {
        let borrower = this.mappingInfo.find(
          a => a.borrowerUserId == this.borrowerInfo[el.borrowerIndex].userId
        );
        if (borrower && borrower.coBorrowerUserId) {
          this.borrowerInfo.forEach((element, i) => {
            if (borrower.coBorrowerUserId == element.userId)
              el.coBorrowerIndex.push(i);
          });
        }
      });
      return relationshipMapping;
    } catch (error) {
      console.log(error);
    }
  }

  public addDuplicateVariables() {
    try {
      this.borrowerInfo.forEach(element => { element["duplicateEmail"] = false; });
    } catch (error) {
      console.log(error);
    }
  }

  public async getFnmBorrowerInfo() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "los/getFnmBorrowerInfoReading",
        {
          ediFile: this.$route.query.ediFile
        });
      if (response.data.data.length > 0) {
        this.borrowerInfo = response.data.data;
      } else {
        this.$snotify.error(
          "Your fnm file seems to be incorrect.Please upload Correct File"
        );
        this.$router.push({
          path: this.$getCurrentUserTypePath("import-3.2"),
          query: { id: this.$route.query.id, source: "import-3.2" }
        });
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$router.push({
          path: this.$getCurrentUserTypePath("import-3.2"),
          query: { id: this.$route.query.id, source: "import-3.2" }
        });
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public async getDontAsk() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loan/getDoNotAskAgain",
        {
          userId: this.$userId,
          userType: this.$userType
        });
      this.dontAsk = response.data.dontAsk;
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    if (this.$route.query.source == "import-3.2") {
      this.selectedDatatype = this.dataType;
      await this.getFnmBorrowerInfo();
      this.loanTxnId = this.$random(8);
      this.$route.query.id = this.loanTxnId;
      this.selectedDatatype = "fnmRead";
    } else {
      this.borrowerInfo = this.borrowers;
      this.selectedDatatype = this.dataType;
      this.loanTxnId = this.$route.query.id;
      this.addDuplicateVariables();
    }
    await this.getDontAsk();
  }
}
