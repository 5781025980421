

import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import AddFnmBorrowerInfo from "../add-fnm-borrowerInfo/AddFnmBorrowerInfo.vue";

@Component({
  components: {
    AddFnmBorrowerInfo
  }
})
export default class CompareBorrowerInfo extends Vue {
  public ssnToDelete: any = null;
  public borrowerInfo = [];
  public fnmBorrowerInfo = [];
  public dataType: any = "currentData";
  public borrowerNames = [];
  public coBorrowerNames = [];
  public data: Boolean = false;
  public duplicateFnmSelect: Boolean = false;
  public deleteBorrowerIndex: any = null;
  public headerName: any = "Compare BorrowerInfo";
  public updateText: any = "Set Existing Borrower Data";
  public mappingInfo = [];
  public deleteSingleBorrower = false;
  public borrowerType = null;


  public async getBorrowerInfo() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.get(
        BASE_API_URL + "broker/getBorrowerInfo/" + this.$route.query.id);
      this.borrowerInfo = response.data.borrowerData;
      this.borrowerInfo.forEach(e => {
        e["fnmBorrowerName"] = {
          firstName: null,
          lastName: null,
          phone: null,
          email: null,
          ssn: null
        };
        e["duplicateData"] = false;
      });
      await this.createMapping(this.borrowerInfo);
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;

      console.log(error);
    }
  }

  public async createMapping(borrowerInfo) {
    try {
      this.mappingInfo = [];
      this.borrowerInfo.forEach(el=>{
        if(el.userType == 'Borrower'){
          this.mappingInfo.push({borrowerUserId: el.userId, coBorrowerUserId: null});
        }else{
          this.mappingInfo[this.mappingInfo.length-1].coBorrowerUserId = el.userId;
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
  public async getFnmBorrowerInfo() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "loan/getFnmBorrowerInfoReading",

        {
          ediFile: this.$route.query.ediFile
        });
      this.fnmBorrowerInfo = response.data.data;
      this.fnmBorrowerInfo.forEach((e, i) => {
        if (i != 0) {
          if (e.personalInfo.userType == "Borrower") {
            this.borrowerNames.push({
              firstName: e.personalInfo.name.firstName,
              lastName: e.personalInfo.name.lastName,
              phone: e.personalInfo.contactInformation.cellPhone,
              email: e.personalInfo.contactInformation.email,
              ssn: e.personalInfo.ssn
            });
          } else {
            this.coBorrowerNames.push({
              firstName: e.personalInfo.name.firstName,
              lastName: e.personalInfo.name.lastName,
              phone: e.personalInfo.contactInformation.cellPhone,
              email: e.personalInfo.contactInformation.email,
              ssn: e.personalInfo.ssn
            });
          }
        }
      });
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$router.push({
          path: this.$getCurrentUserTypePath("import-3.2"),
          query: { id: this.$route.query.id, source: "import-3.2" }
        });
      this.$store.state.wemloLoader = false;
    }
  }

  public async openModal() {
    try {
      this.duplicateFnmSelect = false;
      this.borrowerInfo.forEach((e, i) => {
        if (e.duplicateData) {
          this.duplicateFnmSelect = true;
        }
      });
      if (this.duplicateFnmSelect && this.dataType == 'currentData') {
        this.$modal.hide("confirmToSetData");
        return;
      } else {
        this.$modal.show("confirmToSetData");
      }
    } catch (error) {
      console.log(error);
    }
  }

  // FILTERING THE SSN ID OF BORROWER TO UNREAD THE FNM DATA

  public async findSsnToDelete() {
    try {
      this.duplicateFnmSelect = false;
      this.borrowerInfo.forEach((e, i) => {
        if (e.duplicateData) {
          this.duplicateFnmSelect = true;
        }
      });
      if (this.duplicateFnmSelect && this.dataType == 'currentData') {
        this.$modal.hide("confirmToSetData");
        return;
      }
      //FINDING SSN ID TO DELETE
      if (this.dataType == "currentData") {
        if (this.borrowerInfo.length == 1 && this.fnmBorrowerInfo.length > 0) {
          this.ssnToDelete = this.fnmBorrowerInfo.filter((k, i) => i != 0);
          this.ssnToDelete = this.ssnToDelete.map(
            data => data.personalInfo.ssn
          );
        }
        if (this.borrowerInfo.length > 1 && this.fnmBorrowerInfo.length > 1) {
          let a = this.borrowerInfo.filter(
            (b, i) => i != 0 && b.fnmBorrowerName.ssn
          );
          a = a.map(data => data.fnmBorrowerName.ssn);
          if (a.length > 0) {
            this.ssnToDelete = this.fnmBorrowerInfo.filter(
              (b, i) => i != 0 && a.find(a => a == b.personalInfo.ssn) == null
            );
            // if (this.ssnToDelete.length > 0) {
            this.ssnToDelete = this.ssnToDelete.map(data => {
              return data.personalInfo.ssn;
            });

            // }
          } else {
            this.ssnToDelete = this.fnmBorrowerInfo.filter(
              (b, i) => i != 0 && b.personalInfo.ssn
            );
            this.ssnToDelete = this.ssnToDelete.map(data => {
              return data.personalInfo.ssn;
            });
          }
        }
      }
      this.$modal.hide("confirmToSetData");
      await this.setBorrowerInfo();
    } catch (error) {
      console.log(error);
    }
  }

  // FILTERING THE DATA
  public async setBorrowerInfo() {
    try {
      if (this.dataType == "currentData") {
        this.borrowerInfo.forEach(async (borrower, i) => {
          if (i > 0 && borrower.fnmBorrowerName.ssn) {
            borrower.personalInfo.name.firstName =
              borrower.fnmBorrowerName.firstName;
            borrower.personalInfo.name.lastName =
              borrower.fnmBorrowerName.lastName;
            borrower.personalInfo.contactInformation.email =
              borrower.fnmBorrowerName.email;
            borrower.personalInfo.contactInformation.cellPhone =
              borrower.fnmBorrowerName.phone;
            borrower.personalInfo.ssn = borrower.fnmBorrowerName.ssn;
          }
          if( i == 0){
            borrower.personalInfo.ssn = this.fnmBorrowerInfo[0].personalInfo.ssn;
          }
        });
        let filterBorrower = this.borrowerInfo.filter(
          (e, i) => i == 0 || e.fnmBorrowerName.ssn
        );
        let filterBorrower1 = this.borrowerInfo.filter(
          (e, i) => i != 0 && !e.fnmBorrowerName.ssn
        );
        this.borrowerInfo = filterBorrower.concat(filterBorrower1);
        this.data = true;
        this.headerName = "Add BorrowerInfo";
      } else {
        this.borrowerInfo = this.fnmBorrowerInfo;
        this.data = true;
        this.headerName = "Add BorrowerInfo";
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async cancel() {
    try {
      // this.dataType = null;
      this.$modal.hide("confirmToSetData");
    } catch (error) {
      console.log(error);
    }
  }

  public async linkFnmBorrower() {
    try {
      this.borrowerInfo.forEach(el => {
        el.duplicateData = false;
      });
      this.borrowerInfo.forEach((e, i) => {
        this.borrowerInfo.forEach((el, j) => {
          if (i != 0 && j != 0 && i != j) {
            if (
              e.fnmBorrowerName.firstName &&
              el.fnmBorrowerName.firstName &&
              e.fnmBorrowerName.firstName == el.fnmBorrowerName.firstName &&
              e.fnmBorrowerName.ssn == el.fnmBorrowerName.ssn
            ) {
              e.duplicateData = true;
            }
          }
        });
      });
      this.borrowerInfo = JSON.parse(JSON.stringify(this.borrowerInfo));
    } catch (error) {
      console.log(error);
    }
  }

  public async deleteBorrower(index,borrowerType) {
    try {
      this.deleteSingleBorrower = false;
      let borrowerType = this.borrowerInfo[index].userType;
      if (borrowerType == "Borrower") {
        this.borrowerType = borrowerType;
        let haveCoBorrower = null;
        if (this.borrowerInfo.length > index + 1)
          haveCoBorrower = this.borrowerInfo[index + 1].userType;
        if (haveCoBorrower && haveCoBorrower == "Co-Borrower") {
          this.deleteBorrowerIndex = index;
          this.$modal.show("deleteConformationModal");
          this.deleteSingleBorrower = false;
        } else {
          this.deleteSingleBorrower = true;
          this.deleteBorrowerIndex = index;
          this.$modal.show("deleteConformationModal");
          // this.borrowerInfo.splice(index, 1);
        }
      } else {
         this.borrowerType = borrowerType;
        this.deleteSingleBorrower = true;
        this.deleteBorrowerIndex = index;
        this.$modal.show("deleteConformationModal");

        // this.borrowerInfo.splice(index, 1);
      }
      await this.linkFnmBorrower();
    } catch (error) {
      console.log(error);
    }
  }

  public async unlinkBorrower(type) {
    try {
      if (type == "unlink") {
        this.borrowerInfo.splice(this.deleteBorrowerIndex, 1);
        this.borrowerInfo[this.deleteBorrowerIndex].userType = "Borrower";
        this.borrowerInfo[
          this.deleteBorrowerIndex
        ].personalInfo.relationWithBorrower = "Partner";
      } else if (type == "deleteBoth") {
        this.borrowerInfo.splice(this.deleteBorrowerIndex, 2);
      } else {
        this.borrowerInfo.splice(this.deleteBorrowerIndex, 1);
      }
      await this.linkFnmBorrower();
      this.deleteBorrowerIndex = null;
      this.$modal.hide("deleteConformationModal");
    } catch (error) {
      console.log(error);
    }
  }

  async beforeMount() {
    await this.getBorrowerInfo();

    await this.getFnmBorrowerInfo();
  }
}
